import { api } from ":mods";
import { ENDPOINTS } from "../const";
import { SubmissionDetails, SubmissionsListDetails } from "../models";

export async function getSubmissionsList(stageID: string, statusID: string): Promise<SubmissionsListDetails> {
  return api
    .getAuth(ENDPOINTS.submission_list.replace(":stageId", stageID).replace(":statusId", statusID))
    .then((res) => res.data);
}

export async function getSubmissionsDetails(stageID: string, applicationID: string): Promise<SubmissionDetails> {
  return api
    .getAuth(ENDPOINTS.submission_detials.replace(":stageId", stageID).replace(":applicationID", applicationID))
    .then((res) => res.data);
}

export async function updateSubmissionsStatus(
  stageID: string,
  applicationID: string,
  status: string
): Promise<SubmissionDetails> {
  return api
    .patchAuth(ENDPOINTS.submission_detials.replace(":stageId", stageID).replace(":applicationID", applicationID), {
      application_status: parseInt(status),
    })
    .then((res) => res.data);
}

export async function getSubmissionsStudent(
  applicationID: string,
  type?: "task" | "question",
  taskOrQuesionID?: string | number
): Promise<any> {
  let route = ENDPOINTS.submission_student.replace(":applicationID", applicationID);
  if (type === "task") route += `?task_id=${String(taskOrQuesionID)}`;
  else if (type === "question") route += `?question_id=${String(taskOrQuesionID)}`;
  return api.getAuth(route).then((res) => {
    return res.data;
  });
}
